<template>
  <v-card class="ma-4">
    <v-toolbar>
      <v-toolbar-title>System Links</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-toolbar>
      <v-select v-model="selectedCategory" :items="categories" label="Category" item-text="category" return-object outlined dense hide-details class="mr-2" style="max-width:300px">
        <template v-slot:append-item>
          <v-divider></v-divider>
          <v-list-item @click="addCategory">
            <v-list-item-title>Add Category</v-list-item-title>
          </v-list-item>
        </template>
      </v-select>
      <v-dialog v-model="addGroupDialog" width="400">
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" :disabled="selectedCategory._id === ''" color="success">
            <v-icon left>fal fa-plus</v-icon>
            Add Group
          </v-btn>
        </template>
        <v-card>
          <v-card-title>Add Group</v-card-title>
          <v-card-text>
            <v-text-field v-model="groupName" label="Group Name" outlined />
          </v-card-text>
          <v-card-actions>
            <v-btn :disabled="groupName === ''" color="success" text @click="addGroup">Add Group</v-btn>
            <v-spacer></v-spacer>
            <v-btn text @click="addGroupDialog = false">Cancel</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-toolbar>
    <v-expansion-panels>
      <v-expansion-panel v-for="{ _id, name, links } in selectedCategory.groups" :key="_id">
        <v-expansion-panel-header>{{ name }}</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-list>
            <v-list-item v-for="{ _id, title, subtitle, icon, href, to } in links" :key="_id">
              <v-list-item-avatar v-if="icon">
                <v-icon>{{ icon }}</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ title }}</v-list-item-title>
                <v-list-item-subtitle v-if="subtitle">{{ subtitle }}</v-list-item-subtitle>
                <v-list-item-subtitle>{{ href || to }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn icon @click="startEdit(_id)">
                  <v-icon>fal fa-pencil</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <!-- <v-data-table :items="showCategories ? categories : links" :headers="headers">
      <template v-slot:item.icon="{ item }">
        <v-icon>{{ item.icon }}</v-icon>
      </template>
      <template v-slot:item.link="{ item }">
        {{ item.href || 'https://portal.covenant.edu/' + item.to }}
      </template>
      <template v-slot:item.editRoles="{ item }">
        <v-chip v-for="role in item.editRoles" :key="role" small-chip class="mr-1">{{ role }}</v-chip>
      </template>
      <template v-slot:item.accessRoles="{ item }">
        <v-chip v-for="role in item.accessRoles" :key="role" small-chip class="mr-1">{{ role }}</v-chip>
      </template>
    </v-data-table> -->
    <v-dialog v-model="categoryDialog" width="400">
      <v-card>
        <v-card-title>{{ link._id ? 'Edit' : 'Add' }} Link</v-card-title>
        <v-card-text>
          <v-text-field v-model="category.category" :error-messages="categoryError" label="Category" item-value="category" item-text="category" outlined></v-text-field>
          <v-select v-model="category.editRoles" :items="editRoles" label="Roles which can add/edit/remove links to/from this category" outlined multiple chips small-chips></v-select>
        </v-card-text>
        <v-card-actions>
          <v-btn text color="success" @click="save">{{ category._id ? 'Update' : 'Add' }} Category</v-btn>
          <v-spacer></v-spacer>
          <v-btn text @click="categoryDialog = false">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="linkDialog" width="600">
      <v-card>
        <v-card-title>{{ link._id ? 'Edit Link' : 'Add Link' }}</v-card-title>
        <v-card-text>
          <v-row>
            <v-col :cols="12" md="6">
              <v-select v-model="link.category" :items="categories" label="Category" item-value="category" item-text="category" outlined></v-select>
            </v-col>
            <v-col :cols="12" md="6">
              <v-combobox v-model="link.group" :items="groups" label="Group" outlined></v-combobox>
            </v-col>
            <v-col :cols="12" md="6">
              <v-text-field v-model="link.title" label="Title" outlined></v-text-field>
            </v-col>
            <v-col :cols="12" md="6">
              <v-text-field v-model="link.subtitle" label="Subtitle" outlined></v-text-field>
            </v-col>
            <v-col :cols="12" md="8">
              <v-text-field v-model="link.href" label="Complete Link" hint="The full web address; include the https at the beginning; if an email link, preface the email address with mailto:" outlined></v-text-field>
            </v-col>
            <v-col :cols="12" md="4">
              <v-text-field v-model="link.icon" label="FontAwesome Icon" hint="Click ? to search" outlined>
                <template v-slot:append>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" href="https://fontawesome.com/v5/search" target="_blank" icon>
                        <v-icon color="info">fas fa-question-circle</v-icon>
                      </v-btn>
                    </template>
                    <span>Click this link to open a new tab to FontAwesome version 5, where you can search for the icon. We have the Pro license, so all icons should be available. When you find the icon you want to use, click on it and copy what is in the class text (i.e. &lt;i class="fas fa-question-circle"&gt;, you would copy the "fas fa-question-circle" but without the quotes).</span>
                  </v-tooltip>
                </template>
              </v-text-field>
            </v-col>
            <v-col :cols="12">
              <v-select v-model="link.accessRoles" label="Who can access this link" hint="Leave blank for everyone" persistent-hint outlined></v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn text color="success" @click="save">{{ link._id ? 'Update' : 'Add' }} Link</v-btn>
          <v-spacer></v-spacer>
          <v-btn text @click="linkDialog = false">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
import { computed, onMounted, ref } from '@vue/composition-api'
import paths from '@/router/paths'

export default {
  setup (props, { root }) {
    const user = computed(() => root.$store.state.user.spoof || root.$store.state.user)
    const categories = ref([])
    // const links = ref([])
    const editRoles = ref(['Technology Services', 'Financial Aid', 'Records Office', 'Center for Calling and Career', 'Employees'])
    const showCategories = ref(false)
    const headers = computed(() => {
      if (showCategories.value) {
        return [
          { text: 'Category', value: 'category' },
          { text: 'Roles who can Edit', value: 'editRoles' }
        ]
      } else {
        return [
          { text: 'Category', value: 'category' },
          { text: 'Group', value: 'group' },
          { text: 'Title', value: 'title' },
          { text: 'Link', value: 'link' }
        ]
      }
    })
    // const service = root.$feathers.service('system/link')

    const link = ref({
      _id: '',
      category: '',
      group: '',
      title: '',
      subtitle: '',
      href: '',
      to: '',
      icon: '',
      editRoles: [],
      accessRoles: [],
      available: []
    })
    const linkDialog = ref(false)
    const selectedCategory = ref({
      _id: ''
    })
    const category = ref({
      _id: '',
      category: '',
      editRoles: [],
      accessRoles: []
    })
    const categoryDialog = ref(false)
    const categoryError = computed(() => {
      const cat = category.value.category
      if (cat === '') return []
      if (categories.value.filter(({ category }) => category === cat).length > 0) {
        return ['Category with entered name already exists']
      }
      return []
    })
    async function addCategory () {
      for (const l in category.value) {
        category.value[l] = ''
      }
      categoryDialog.value = true
    }
    const addGroupDialog = ref(false)
    const groupName = ref('')
    async function addGroup () {
      // Make sure the group name is unique
      // If not, give an error
      // If so, add the group to the category
      await root.$feathers.service('system/link-category').patch(selectedCategory.value._id, { $push: { groups: { name: groupName.value } } })
      selectedCategory.value.groups.push({ name: groupName.value })
      addGroupDialog.value = false
      groupName.value = ''
    }
    async function addLink () {
      for (const l in link.value) {
        link.value[l] = ''
      }
      linkDialog.value = true
    }
    async function startEdit (id) {
      if (showCategories.value) {
        const rec = categories.value.find(({ _id }) => _id === id)
        if (rec) {
          for (const l in category.value) {
            category.value[l] = rec[l] || ''
          }
          categoryDialog.value = true
        }
      } else {
        // const rec = links.value.find(({ _id }) => _id === id)
        // if (rec) {
        //   for (const l in link.value) {
        //     link.value[l] = rec[l] || ''
        //   }
        //   if (link.value.href === '' && rec.to !== '') link.value.href = rec.to
        //   linkDialog.value = true
        // }
      }
    }
    async function save () {
      if (showCategories.value) {
        if (category.value.category === '') return
        if (category.value.editRoles.length === 0) return

        const patch = {
          category: category.value.category,
          editRoles: [ ...category.value.editRoles ]
        }
        if (category.value._id === '') {
          const rec = await root.$feathers.service('system/link-category').create(patch)
          categories.value.push(rec)
        } else {
          const rec = await root.$feathers.service('system/link-category').patch(category.value._id, patch)
          const index = categories.value.findIndex(({ _id }) => _id === category.value._id)
          if (index >= 0) {
            categories.value.splice(index, 1, rec)
          }
        }
        categoryDialog.value = false
      } else {
        if (link.value.category === '') return
        if (link.value.title === '') return
        if (link.value.href === '') return

        const temp = {}
        for (const l in link.value) if (link.value[l] !== '') temp[l] = link.value[l]

        if (link.value.href.substring(0, 28) === 'https://portal.covenant.edu/') {
          const tempLink = link.value.href.substring(27)
          const parts = tempLink.split('/')
          const list = paths.filter(({ path }) => {
            if (path === tempLink) return true
            const arr = path.split('/')
            if (parts[1] === arr[1]) return true
          })
          if (list.length > 0) {
            temp.to = tempLink
            delete temp.href
          }
        }

        // if (link.value._id === '') {
        //   const rec = await service.create(temp)
        //   links.value.push(rec)
        // } else {
        //   delete temp._id
        //   const rec = await service.patch(link.value._id, temp)
        //   const index = links.value.findIndex(({ _id }) => _id === link.value._id)
        //   if (index >= 0) {
        //     links.value.splice(index, 1, rec)
        //   }
        // }
        if (link.value.group !== '') {
          const index = categories.value.findIndex(({ category }) => category === link.value.category)
          const cat = categories.value[index]
          if (!('groups' in cat) || cat.groups.length === 0 || !cat.groups.includes(link.value.group)) {
            const rec = await root.$feathers.service('system/link-category').patch(cat._id, { $push: { groups: link.value.group } })
            categories.value.splice(index, 1, rec)
          }
        }
        linkDialog.value = false
      }
    }

    const groups = computed(() => {
      const rec = categories.value.find(({ category }) => category === link.value.category)
      if (rec) {
        return rec.groups
      }
      return []
    })

    onMounted(async () => {
      const aggregate = [
        { $match: { editRoles: { $in: user.value.roles } } },
        { $unwind: '$groups' },
        { $project: {
          _id: 1,
          category: 1,
          editRoles: 1,
          accessRoles: 1,
          group: '$groups.name',
          links: '$groups.links'
        } },
        { $lookup: {
          from: 'System-Links',
          localField: 'links',
          foreignField: '_id',
          as: 'links'
        } },
        { $group: {
          _id: {
            category: '$category',
            editRoles: '$editRoles',
            accessRoles: '$accessRoles'
          },
          groups: {
            $push: {
              name: '$group',
              links: '$links'
            }
          }
        } },
        { $addFields: {
          category: '$_id.category',
          editRoles: '$_id.editRoles',
          accessRoles: '$_id.accessRoles'
        } }
      ]
      const { data } = await root.$feathers.service('system/link-category').find({ query: { aggregate } })
      categories.value = data
    })

    return {
      user,
      // links,
      categories,
      editRoles,
      showCategories,
      headers,
      link,
      linkDialog,
      selectedCategory,
      category,
      categoryDialog,
      categoryError,
      addCategory,
      addGroupDialog,
      groupName,
      addGroup,
      addLink,
      startEdit,
      save,
      groups
    }
  }
}
</script>
